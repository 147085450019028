import React from "react";
import Control from "../../common/Control";
import Loader from "../../common/Loader";
import { DateTime } from "luxon";

const RdvFilters = ({
  filters,
  organizations,
  sites,
  updateFilter,
  filtersLoading,
  rdvs,
  resetFilters,
  submit,
}) => {
  const hasSelectedOrganization =
    filters.organization != "" && organizations.length > 0;

  const hasSelectedSite = filters.site != "" && sites.length > 0;
  return (
    <div className="row" style={{ position: "relative" }}>
      {filtersLoading && <Loader overlay={true} />}

      <div className="col-12 col-md-4 col-lg-4 animated fadeInRight faster">
        <Control
          className="px-0"
          label="Organisation"
          type="select"
          name="organization"
          datas={organizations}
          value={filters.organization}
          change={updateFilter}
          selectFirstLabel={"Choisissez une organisation"}
          margin={"my-0"}
        />
      </div>
      <div className="col-12 col-md-4 col-lg-4 animated fadeInRight faster">
        {hasSelectedOrganization && (
          <Control
            className="px-0"
            label="Site"
            type="select"
            name="site"
            datas={sites}
            value={filters.site}
            change={updateFilter}
            selectFirstLabel={"Choisissez un site"}
            margin={"my-0"}
          />
        )}
      </div>
      <div className="col-12 col-md-4 col-lg-4 animated fadeInRight faster">
        {hasSelectedSite && (
          <Control
            className="px-0"
            label="Box"
            type="select"
            name="box"
            dataIndex={"_id"}
            datas={sites.find((s) => s.id == filters.site).boxes}
            value={filters.box}
            change={updateFilter}
            selectFirstLabel={"Tous les box"}
            margin={"my-0"}
          />
        )}
      </div>

      <div className="col-12 col-md-4 col-lg-4 animated fadeInRight faster">
        <Control
          label="Traités"
          type="checkbox"
          name="isTreated"
          checked={filters.isTreated}
          change={updateFilter}
          margin={"my-2"}
        />
        <Control
          label="Non Traités"
          type="checkbox"
          name="isNotTreated"
          checked={filters.isNotTreated}
          change={updateFilter}
          margin={"my-2"}
        />
      </div>
      <div className="col-12 col-md-4 col-lg-4 animated fadeInRight faster">
        <Control
          label="Clôturés"
          type="checkbox"
          name="isClosed"
          checked={filters.isClosed}
          change={updateFilter}
          margin={"my-2"}
        />
        <Control
          label="Non Clôturés"
          type="checkbox"
          name="isNotClosed"
          checked={filters.isNotClosed}
          change={updateFilter}
          margin={"my-2"}
        />
      </div>
      <div className="col-12 col-md-4 col-lg-4 animated fadeInRight faster">
        <Control
          className="px-0"
          label="Agent attribué"
          type="select"
          name="userManager"
          value={filters.userManager}
          datas={rdvs
            .filter((r) => r.userManager)
            .map((r) => r.userManager)
            .filter(
              (r, index, self) => index === self.findIndex((t) => t.id == r.id)
            )}
          dataIndex={"id"}
          dataLabel={"firstname"}
          dataLabel2={"lastname"}
          change={updateFilter}
          selectFirstLabel="Tous"
          margin={"my-0"}
        />
      </div>
      <div className="col-12 text-right">
        <button className="btn btn-default btn-sm" onClick={resetFilters}>
          <i className="fa fa-sync-alt mr-2" />
          Réinitialiser les filtres
        </button>
      </div>
      <div className="col-12 animated fadeInRight faster mx-auto">
        <Control
          className="px-0"
          label="Jour"
          type="daySelector"
          name="start"
          value={DateTime.fromISO(filters.start).toFormat("yyyy-MM-dd")}
          change={updateFilter}
          selectFirstLabel={"Choisissez un jour"}
          margin={"my-0"}
        />
      </div>
      {/* <div className="col-6 animated fadeInRight faster mx-auto">
        <Control
          className="px-0"
          label="Et le"
          type="daySelector"
          name="end"
          value={DateTime.fromISO(filters.end).toFormat("yyyy-MM-dd")}
          change={updateFilter}
          selectFirstLabel={"Choisissez un jour"}
          margin={"my-0"}
        />
      </div> */}
      <div className="col-12 text-center mt-3">
        <button
          className="btn btn-primary mx-auto btn-lg"
          onClick={submit}
          disabled={!hasSelectedOrganization || !hasSelectedSite}
        >
          Rechercher
        </button>
      </div>
    </div>
  );
};

export default RdvFilters;
