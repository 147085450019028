import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Page from "../../common/layout/Page";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import Table from "../../common/Table";
import tools from "../../../helpers/tools";
import RecipientListFilters from "./RecipientListFilters";
import dataService from "../../../helpers/dataService";
import { DateTime } from "luxon";
import SaveBtn from "../../common/SaveBtn";

const RecipientList = (props) => {
  const [recipients, setRecipients] = useState([]);
  const [state, dispatch] = useStore();
  const constants = state.constants.items;
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  var searchState = tools.getState("RecipientList", null);
  const initialSearchState = searchState
    ? JSON.parse(searchState.search)
    : {
        filters: {
          terms: "",
          isActivated: "",
          origin: "",
          rdspAvailable: "",
          orientation: "",
          maritalStatus: "",
          lastOrientationAt: "",
          isLastOrientationAfterEG: false,
          archivedAt: "",
          professionalSituation: "",
        },
        page: 1,
        nbItemsByPage: 25,
      };
  const [search, setSearch] = useState(initialSearchState);
  const [nbPages, setNbPages] = useState(1);
  const [nbResults, setNbResults] = useState(0);
  const [orgs, setOrgs] = useState([]);

  useEffect(() => {
    dataService.get("organizations/orientation_partners", setOrgs);
    document.addEventListener("keyup", keyPressed);

    return () => {
      document.removeEventListener("keyup", keyPressed);
    };
  }, []);

  const keyPressed = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      searchRecipients();
    }
  };

  const resetFilters = () => {
    setSearch({
      filters: {
        terms: "",
        isActivated: "",
        origin: "",
        rdspAvailable: "",
        orientation: "",
        maritalStatus: "",
        lastOrientationAt: "",
        isLastOrientationAfterEG: false,
        archivedAt: "",
      },
      page: 1,
      nbItemsByPage: 25,
    });
  };

  useEffect(() => {
    // setIsLoading(true);
    // searchRecipients();
  }, []);

  function searchRecipients(pagi = false, nbi = false) {
    setErrors({});
    setIsLoading(true);

    var sh = {
      ...search,
      page: pagi || search.page,
      nbItemsByPage: nbi || search.nbItemsByPage,
    };

    tools.saveState("RecipientList", "search", JSON.stringify(sh));
    dataService.post(
      "recipients/search",
      { ...sh },
      (datas) => {
        setRecipients(datas.data);
        setNbPages(Math.ceil(parseInt(datas.count) / sh.nbItemsByPage));
        setNbResults(datas.count);
        setIsLoading(false);
      },
      (errors) => {
        setErrors(errors);
        setRecipients([]);
      },
      () => setIsLoading(false)
    );
  }
  function updateSearch(e) {
    let { value, type, checked } = e.target;
    var su = { ...search };

    su.filters[e.target.name] =
      type == "checkbox" ? (checked ? true : false) : value;

    su.page = 1;

    setSearch(su);
  }

  function changePage(i) {
    searchRecipients(i);
    setSearch((search) => {
      return { ...search, page: i };
    });
  }
  function changeNbItemsByPage(i) {
    searchRecipients(false, i);
    setSearch((search) => {
      return { ...search, nbItemsByPage: i };
    });
  }

  return (
    <>
      <Page
        container={"container px-5"}
        title={"Allocataires"}
        action={{
          to: "/recipients-create",
          text: "Créer un allocataire",
          dataPriv: "create_recipient",
        }}
      >
        <div className="row">
          <div className="col-12 justify-content-center">
            <RecipientListFilters
              search={search}
              updateSearch={updateSearch}
              resetFilters={resetFilters}
              searchRecipients={() => searchRecipients()}
              orgs={orgs}
            />
          </div>

          <div className="col-12">
            <SaveBtn
              className=" w-100 d-center font-weight-bold py-3"
              type="btn-primary"
              typeBtn="button"
              isSaving={isLoading}
              save={() => searchRecipients()}
              text="Rechercher"
            />
          </div>
          <div className="col-12 align-items-center justify-content-center d-flex mt-2 font-weight-bold">
            {nbResults} allocataires trouvés
          </div>
        </div>
      </Page>
      {state.constants.items.length == 0 || !orgs.length || isLoading ? (
        <Loader />
      ) : (
        <div
          style={{
            maxWidth: "100%",
            overflowX: "scroll",
            overflowY: "hidden",
          }}
          className="mx-auto"
        >
          {errors && errors.other ? (
            <div className="text-danger m-auto text-center">{errors.other}</div>
          ) : isLoading ? (
            <Loader />
          ) : (
            <>
              <Table
                datas={recipients}
                exportOptions={{
                  fetchDatas: "recipients/search",
                  fetchParams: {
                    ...search,
                    nbItemsByPage: "*",
                  },
                  formatDatasFn: (datas) =>
                    datas.map((r) => ({
                      ...r,
                      rdsp: r?.orientation?.orientation,
                      orientation: orgs.find(
                        (m) => m.slugName == r.orientation?.orientation
                      )
                        ? tools.findIn(
                            orgs,
                            "slugName",
                            r.orientation?.orientation
                          ).name
                        : "-",
                      orientationDate: r.orientation?.createdAt
                        ? tools.formatDate(r.orientation.createdAt)
                        : "",
                      rdspAvailable: r.rdspAvailable ? "Non" : "Oui",
                      isActivated: r.isActivated ? "Oui" : "Non",
                      name: tools.getFullname(r),
                      eg:
                        r.orientationBeforeLast?.orientation === "CAF_EG"
                          ? tools.formatDate(r.orientationBeforeLast.createdAt)
                          : "",
                      professionalSituation: r.professionalSituation
                        ? tools.findIn(
                            constants.PROFESSIONAL_SITUATIONS,
                            "id",
                            r.professionalSituation
                          ).name
                        : "",
                      updatedFromSIDAt: r.updatedFromSIDAt
                        ? tools.formatDate(r.updatedFromSIDAt)
                        : "",
                      lastRdspAt: r.lastRdspAt,
                      epOrientationDate:
                        r.orientation?.type == "EP"
                          ? tools.formatDate(r.orientation.createdAt)
                          : "",
                      lastStateSDD:
                        r.rightsAndDuties?.length > 0
                          ? tools.getEtatSDD(
                              r.rightsAndDuties[r.rightsAndDuties.length - 1]
                            )
                          : "N",
                      lastTypeContract:
                        r.contracts?.length > 0
                          ? tools.findIn(
                              constants.CONTRACT_TYPES,
                              "id",
                              r.contracts[r.contracts.length - 1].type
                            ).name
                          : "",
                      lastContractBeginAt:
                        r.contracts?.length > 0
                          ? tools.formatDate(
                              r.contracts[r.contracts.length - 1].beginAt
                            )
                          : "",
                      lastContractEndAt:
                        r.contracts?.length > 0
                          ? tools.formatDate(
                              r.contracts[r.contracts.length - 1].endAt
                            )
                          : "",
                      createdAt: tools.formatDate(r.createdAt),
                    })),
                  excludedFields: ["Voir"],
                  objectFields: {
                    location: {
                      dataIndexes: ["name", "postalCode"],
                    },
                  },
                  additionalFields: [
                    { name: "Date Orientation", path: "orientationDate" },
                    { name: "Date dernier RDSP", path: "lastRdspAt" },
                    { name: "Date EG", path: "eg" },
                    { name: "Date EP", path: "epOrientationDate" },
                    { name: "Situation pro.", path: "professionalSituation" },
                    { name: "Date maj SID", path: "updatedFromSIDAt" },
                    { name: "Dernier état SDD", path: "lastStateSDD" },
                    { name: "Type dernier contrat", path: "lastTypeContract" },
                    {
                      name: "Début dernier contrat",
                      path: "lastContractBeginAt",
                    },
                    { name: "Fin dernier contrat", path: "lastContractEndAt" },
                    { name: "Date création", path: "createdAt" },
                  ],
                }}
                fields={[
                  {
                    name: "Nom",
                    path: "*",
                    exportPath: "name",
                    render: (elem) => (
                      <>
                        <Link
                          to={`recipients/${elem.id}`}
                          className={`btn btn-link text-left ${
                            elem.archivedAt ? "text-black-50" : ""
                          }`}
                        >
                          {tools.getFullname(elem)}
                        </Link>
                        {elem.archivedAt && (
                          <small className="badge badge-dark d-block text-center">
                            <i className="fa fa-archive mr-1" />
                            Archivé le{" "}
                            {DateTime.fromISO(elem.archivedAt).toFormat(
                              "dd/MM/yyyy"
                            )}
                          </small>
                        )}
                      </>
                    ),
                  },
                  {
                    name: "NIR",
                    path: "nir",
                  },
                  {
                    name: "Matr.",
                    path: "CAFId",
                  },
                  {
                    name: "Email",
                    path: "*",
                    exportPath: "email",
                    render: (elem) => (
                      <div>
                        <a
                          href={`mailto: ${elem.email}`}
                          style={{ color: "inherit" }}
                        >
                          {elem.email}
                        </a>
                        <br />
                        {elem.emailOrigin && (
                          <div className="badge badge-info ">
                            sid : {elem.emailOrigin}
                          </div>
                        )}
                      </div>
                    ),
                  },
                  {
                    name: "Tél",
                    path: "phone",
                  },
                  {
                    name: "Activé",
                    path: "isActivated",
                    render: (elem) => (
                      <div
                        className={`badge badge-${elem ? "success" : "danger"}`}
                      >
                        <i className={`fa fa-${elem ? "check" : "times"}`} />
                      </div>
                    ),
                  },
                  {
                    name: "RDSP",
                    path: "rdspAvailable",
                    render: (elem) => (
                      <div
                        className={`badge badge-${
                          !elem ? "success" : "danger"
                        }`}
                      >
                        <i className={`fa fa-${!elem ? "check" : "times"}`} />
                      </div>
                    ),
                  },
                  // {
                  //   name: "Sit.Fam.",
                  //   path: "maritalStatus",
                  //   render: (elem) =>
                  //     elem &&
                  //     constants.MARITAL_STATUS.find((m) => m.id == elem)
                  //       .name,
                  // },
                  {
                    name: "Lieu",
                    path: "location",
                    render: (elem) => elem.postalCode + " " + elem.name,
                  },
                  {
                    name: "Origine",
                    path: "origin",
                    render: (elem) =>
                      elem &&
                      constants.RECIPIENT_ORIGINS.find((m) => m.id == elem) &&
                      constants.RECIPIENT_ORIGINS.find((m) => m.id == elem)
                        .name,
                  },
                  {
                    name: "Orientation",
                    path: "orientation",
                    render: (elem) =>
                      (elem && elem.orientation && (
                        <div>
                          {
                            tools.findIn(orgs, "slugName", elem.orientation)
                              .name
                          }
                          <div className="text-muted" style={{ fontSize: 12 }}>
                            {DateTime.fromISO(elem.createdAt).toFormat(
                              "dd/MM/yyyy"
                            )}
                          </div>
                        </div>
                      )) ||
                      "-",
                  },

                  {
                    name: "Voir",
                    path: "id",
                    render: (elem) => (
                      <Link
                        to={"/recipients/" + elem}
                        className="btn btn-primary w-100 btn-sm d-flex align-items-center justify-content-center"
                      >
                        <i className="fa fa-eye"></i>
                      </Link>
                    ),
                  },
                ]}
              />
            </>
          )}
          <Pagination
            nbPages={nbPages}
            page={search.page}
            changePage={changePage}
            nbItemsByPage={search.nbItemsByPage}
            changeNbItemsByPage={changeNbItemsByPage}
          />
        </div>
      )}
    </>
  );
};

export default RecipientList;
