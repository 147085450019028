import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Page from "../../common/layout/Page";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import tools from "../../../helpers/tools";
import Table from "../../common/Table";
import dataService from "../../../helpers/dataService";

// Configuration pour basculer entre mock et API réelle
const USE_REAL_API = true;

let strTimeout = null;

// Données de test
const generateMockData = () => ({
  data: [
    {
      id: "674442118905ef34b68879f6",
      redisId: "4",
      name: "CalculerOrientationFTQueue",
      queue: "CalculerOrientationFTQueue",
      payload: {
        allocataireId: "6728a1ef3505fb1efcc58e53",
        rdspId: "6728a2063505fb1efcc58e59",
      },
      exception: {
        stack:
          "Error: Request failed with status code 500\n    at FranceTravailConnector.send (/Users/romaingrau/_work/10positif/CAF/CAF974/ESPOAR/espoar-api/src/Infrastructure/Integrations/Http/BaseConnector.js:105:19)\n    at process.processTicksAndRejections (node:internal/process/task_queues:95:5)\n    at async CalculerOrientationFTAction.handle (/Users/romaingrau/_work/10positif/CAF/CAF974/ESPOAR/espoar-api/src/Domains/Allocataire/Orientation/Actions/CalculerOrientationFTAction.js:42:46)\n    at async Queue.<anonymous> (/Users/romaingrau/_work/10positif/CAF/CAF974/ESPOAR/espoar-api/app/Jobs/Orientation/CalculerOrientationFTQueue.js:16:17)",
        message: "Request failed with status code 500",
      },
      failedAt: "2024-01-25T09:23:29.500Z",
    },
    {
      id: "674442118905ef34b68879f7",
      redisId: "7",
      name: "RecupererDonneesFTQueue",
      queue: "RecupererDonneesFTQueue",
      payload: {
        allocataireId: "6728a1ef3505fb1efcc58e54",
        rdspId: "6728a2063505fb1efcc58e60",
      },
      exception: {
        stack:
          "Error: Network Error\n    at FranceTravailConnector.send (/Users/romaingrau/_work/10positif/CAF/CAF974/ESPOAR/espoar-api/src/Infrastructure/Integrations/Http/BaseConnector.js:98:15)\n    at process.processTicksAndRejections (node:internal/process/task_queues:95:5)",
        message: "Network Error: Unable to reach France Travail API",
      },
      failedAt: "2024-01-24T15:45:12.123Z",
    },
    {
      id: "674442118905ef34b68879f8",
      redisId: "12",
      name: "MajOrientationFTQueue",
      queue: "MajOrientationFTQueue",
      payload: {
        rdspId: "6728a2063505fb1efcc58e61",
      },
      exception: {
        stack:
          "TypeError: Cannot read properties of undefined (reading 'allocataireId')\n    at MajOrientationFTAction.handle (/Users/romaingrau/_work/10positif/CAF/CAF974/ESPOAR/espoar-api/src/Domains/Allocataire/Orientation/Actions/MajOrientationFTAction.js:28:35)\n    at async Queue.<anonymous>",
        message:
          "Cannot read properties of undefined (reading 'allocataireId')",
      },
      failedAt: "2024-01-23T08:15:45.789Z",
    },
    {
      id: "674442118905ef34b68879f9",
      redisId: "15",
      name: "SynchroniserDossierFTQueue",
      queue: "SynchroniserDossierFTQueue",
      payload: {
        allocataireId: "6728a1ef3505fb1efcc58e55",
        rdspId: "6728a2063505fb1efcc58e62",
      },
      exception: {
        stack:
          "Error: Invalid response format\n    at FranceTravailConnector.parseResponse (/Users/romaingrau/_work/10positif/CAF/CAF974/ESPOAR/espoar-api/src/Infrastructure/Integrations/Http/BaseConnector.js:156:11)\n    at async SynchroniserDossierFTAction.handle",
        message: "Invalid response format from France Travail API",
      },
      failedAt: "2024-01-22T11:30:00.456Z",
    },
    {
      id: "674442118905ef34b68879fa",
      redisId: "18",
      name: "CalculerOrientationFTQueue",
      queue: "CalculerOrientationFTQueue",
      payload: {
        allocataireId: "6728a1ef3505fb1efcc58e56",
        rdspId: "6728a2063505fb1efcc58e63",
      },
      exception: {
        stack:
          "Error: Request timeout after 30000ms\n    at FranceTravailConnector.send (/Users/romaingrau/_work/10positif/CAF/CAF974/ESPOAR/espoar-api/src/Infrastructure/Integrations/Http/BaseConnector.js:112:23)\n    at async CalculerOrientationFTAction.handle",
        message: "Request timeout after 30000ms",
      },
      failedAt: "2024-01-21T16:20:15.234Z",
    },
  ],
  count: 5,
});

const mockData = generateMockData();

const OrientationJobList = () => {
  const [jobs, setJobs] = useState([]);
  const [state, dispatch] = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [search, setSearch] = useState({
    page: 1,
    nbItemsByPage: 25,
  });
  const [nbPages, setNbPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [retriedJobs, setRetriedJobs] = useState(new Set());
  const [retryingJobs, setRetryingJobs] = useState(new Set());

  const changePage = (newPage) => {
    setSearch({ ...search, page: newPage });
  };

  const changeNbItemsByPage = (newNbItems) => {
    setSearch({ ...search, nbItemsByPage: newNbItems, page: 1 });
  };

  const retryJob = async (jobId) => {
    try {
      setRetryingJobs((prev) => new Set([...prev, jobId]));

      await dataService.get(
        `jobs/failed/${jobId}/retry`,
        () => {
          toast.success("Job relancé avec succès");
          setRetriedJobs((prev) => new Set([...prev, jobId]));
        },
        (error) => {
          console.error("Erreur lors de la relance du job:", error);
          toast.error("Erreur lors de la relance du job");
        }
      );
    } catch (error) {
      console.error("Erreur lors de la relance du job:", error);
      toast.error("Erreur lors de la relance du job");
    } finally {
      setRetryingJobs((prev) => {
        const newSet = new Set(prev);
        newSet.delete(jobId);
        return newSet;
      });
    }
  };

  const fetchJobs = () => {
    setIsLoading(true);
    setErrors({});

    if (USE_REAL_API) {
      // Appel à l'API réelle
      dataService.post(
        "jobs/failed/search",
        {
          filters: {
            terms: searchTerm,
          },
          page: search.page,
        },
        (response) => {
          setJobs(response.data);
          setTotalCount(response.count);
          setNbPages(Math.ceil(response.count / search.nbItemsByPage));
          setIsLoading(false);
        },
        (error) => {
          console.error("Erreur lors de la récupération des jobs:", error);
          setErrors({ fetch: "Erreur lors de la récupération des données" });
          setIsLoading(false);
          toast.error("Erreur lors de la récupération des jobs");
        }
      );
    } else {
      // Utilisation des données mockées
      setTimeout(() => {
        try {
          let filteredData = mockData.data;
          if (searchTerm) {
            const searchLower = searchTerm.toLowerCase();
            filteredData = mockData.data.filter(
              (job) =>
                job.queue.toLowerCase().includes(searchLower) ||
                job.exception.message.toLowerCase().includes(searchLower) ||
                JSON.stringify(job.payload).toLowerCase().includes(searchLower)
            );
          }

          const startIndex = (search.page - 1) * search.nbItemsByPage;
          const endIndex = startIndex + search.nbItemsByPage;
          const paginatedData = filteredData.slice(startIndex, endIndex);

          setJobs(paginatedData);
          setTotalCount(filteredData.length);
          setNbPages(Math.ceil(filteredData.length / search.nbItemsByPage));
          setIsLoading(false);
        } catch (error) {
          console.error("Erreur lors de la récupération des jobs:", error);
          setErrors({ fetch: "Erreur lors de la récupération des données" });
          setIsLoading(false);
        }
      }, 500);
    }
  };

  useEffect(() => {
    if (strTimeout) clearTimeout(strTimeout);
    strTimeout = setTimeout(() => {
      fetchJobs();
    }, 500);
    return () => {
      if (strTimeout) clearTimeout(strTimeout);
    };
  }, [search, searchTerm]);

  const fields = [
    {
      name: "Date",
      path: "failedAt",
      render: (value) => <strong>{tools.formatDate(value)}</strong>,
    },
    {
      name: "Queue",
      path: "*",
      render: (item) => (
        <div style={{ fontSize: 13 }}>
          <div>
            <strong>Nom:</strong>
            <br />
            {item.name}
          </div>
          <div>
            <strong>Queue:</strong>
            <br />
            {item.queue}
          </div>
          <div>
            <strong>Redis ID:</strong>
            <br />
            {item.redisId}
          </div>
          {item.payload?.allocataireId && (
            <div className="mt-2">
              <Link
                to={`/recipients/${item.payload.allocataireId}`}
                className="btn btn-link p-0"
                target="_blank"
              >
                Voir l'allocataire
              </Link>
            </div>
          )}
        </div>
      ),
    },
    {
      name: "Payload",
      path: "payload",
      render: (payload) => (
        <pre
          className="m-0"
          style={{
            maxWidth: "300px",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            fontFamily: "monospace",
            backgroundColor: "#f8f9fa",
            padding: "0.5rem",
            borderRadius: "4px",
            border: "1px solid #dee2e6",
            fontSize: 11,
          }}
        >
          {JSON.stringify(payload, null, 2)}
        </pre>
      ),
    },
    {
      name: "Erreur",
      path: "exception",
      render: (value) => (
        <div className="m-0">
          <div className="mb-2 text-danger font-weight-bold">
            {value.message}
          </div>
          <pre
            style={{
              maxWidth: "500px",
              maxHeight: "110px",
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              fontFamily: "monospace",
              fontSize: 11,
              backgroundColor: "#f8f9fa",
              padding: "0.5rem",
              borderRadius: "4px",
              border: "1px solid #dee2e6",
              overflowY: "auto",
              color: "#dc3545", // Couleur rouge Bootstrap
            }}
          >
            {value.stack}
          </pre>
        </div>
      ),
    },
    {
      name: "Actions",
      path: "id",
      render: (jobId) => {
        if (retriedJobs.has(jobId)) {
          return (
            <span className="text-success">
              <i className="fas fa-check-circle mr-2"></i>
              Relancé
            </span>
          );
        }

        if (retryingJobs.has(jobId)) {
          return (
            <div className="d-flex align-items-center">
              <Loader size="sm" />
              <span>Relance...</span>
            </div>
          );
        }

        return (
          <button
            className="btn btn-primary btn-sm"
            onClick={() => retryJob(jobId)}
          >
            <div className="d-flex align-items-center">
              <i className="fas fa-redo-alt mr-2"></i>
              <span>Réessayer</span>
            </div>
          </button>
        );
      },
    },
  ];

  return (
    <Page
      container={"container-fluid px-5"}
      title={"Liste des erreurs d'appel à API FT"}
    >
      <div className="row">
        <div className="col-12 p-4">
          <div className="d-flex justify-content-between mb-3">
            {/* <Control
                            type="text"
                            placeholder="Rechercher..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-25"
                        /> */}
            <button
              className="btn btn-primary"
              onClick={fetchJobs}
              disabled={isLoading}
            >
              Rafraîchir la liste
            </button>
          </div>

          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div>
                Total: {totalCount} résultat{totalCount > 1 ? "s" : ""}
              </div>
              <Table
                datas={jobs}
                fields={fields}
                fullWidth
                tdStyle={{ verticalAlign: "top" }}
              />
              <Pagination
                nbPages={nbPages}
                page={search.page}
                changePage={changePage}
                nbItemsByPage={search.nbItemsByPage}
                changeNbItemsByPage={changeNbItemsByPage}
              />
            </>
          )}
        </div>
      </div>
    </Page>
  );
};

export default OrientationJobList;
