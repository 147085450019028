import React, { useEffect, useRef, useState } from "react";
import { captchaSettings } from "reactjs-captcha";
import { api_url } from "../../../config";
import { authActions } from "../../../context/actions/authActions";
import useStore from "../../../context/useStore";
import colors from "../../../helpers/colors";
import Control from "../../common/Control";
import Page from "../../common/layout/Page";
import SaveBtn from "../../common/SaveBtn";
import Timer from "../../common/Timer/Timer";
import ForgotPassword from "./ForgotPassword";
captchaSettings.set({
  captchaEndpoint: `${api_url}simple-captcha-endpoint`,
});

const Login = () => {
  const captchaRef = useRef();
  const [state, dispatch] = useStore();
  var actions = authActions(state, dispatch);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [canLoggin, setCanLoggin] = useState(true);

  useEffect(() => {
    if (state.errors.login || state.errors.password) {
      setIsLoading(false);
    }

    if (Object.values(state.errors).length > 0) {
      setIsLoading(false);

      if (captchaRef.current) {
        try {
          captchaRef.current.reloadImage();
        } catch (err) {}
      }
      if (state.errors.password) {
        setPassword("");
      }
      if (state.errors.timer) {
        setTimer(parseInt(state.errors.timer));
      }
    }
  }, [state.errors]);

  useEffect(() => {
    setTimer(false);
    setCanLoggin(true);
  }, [forgotPassword]);

  function loginHandle(e) {
    setIsLoading(true);
    e.preventDefault();
    let postData = {
      login,
      password,
    };
    actions.loginUser(postData);
  }
  useEffect(() => {
    if (timer > 0) {
      setCanLoggin(false);
    }
  }, [timer]);

  function timerFinish() {
    setCanLoggin(true);
  }

  return (
    <Page
      bgTransparent
      noMargin={true}
      containerClassname="d-flex flex-column justify-content-center align-items-center h-100 bg-primary"
    >
      {!forgotPassword ? (
        <form
          onSubmit={loginHandle}
          className="card mx-auto px-0 pb-4 animated fadeIn faster overflow-hidden shadow-lg border-0 rounded-0"
          style={{
            maxWidth: 500,
          }}
        >
          <div className="card-header pb-0 px-0 border-0 rounded-0">
            <div
              className="text-center mb-4"
              style={{
                background: colors.primary,
                borderRadius: 5,
                padding: 15,
              }}
            >
              <img
                style={{ maxHeight: 100 }}
                className="img-fluid"
                src="/assets/logo-espoar-new.png"
              />
            </div>
            <h3 className="mb-0">Espace administratif</h3>
            <div
              className="text-center mt-2 font-weight-bold shadow-sm p-2"
              style={{
                color: "yellow",
                borderTop: "1px solid yellow",
                maxWidth: 500,
                fontSize: 13,
              }}
            >
              <i className="fa fa-exclamation-circle mr-2" />
              Pour les utilisateurs de l'organisme France Travail, vous devez
              désormais utiliser votre nouvelle adresse pour vous connecter.{" "}
              <br />
              prenom.nom@francetravail.fr
            </div>
          </div>
          <div className="card-body pb-0 ">
            <Control
              label="Email"
              name="login"
              id="login"
              type="login"
              value={login}
              change={(e) => setLogin(e.target.value)}
              error={state.errors}
              margin={"my-2"}
            />

            <Control
              label="Mot de passe"
              name="password"
              id="password"
              type="password"
              value={password}
              change={(e) => setPassword(e.target.value)}
              error={state.errors}
              margin={"my-2"}
            />
            <div className="text-center d-flex flex-column justify-content-center align-items-center">
              {state.errors.nbAttempts > 0 && (
                <span className="text-primary">
                  {state.errors.nbAttempts} tentatives restantes
                </span>
              )}
              {!canLoggin && (
                <>
                  <span className="text-danger mb-3">
                    L'accès à votre compte est temporairement bloqué suite à
                    plusieurs tentatives de connexion infructueuses.
                  </span>
                  <Timer timeLimit={timer} timerFinish={timerFinish} />
                </>
              )}
            </div>
          </div>
          <div className="text-center m-auto">
            {canLoggin && (
              <SaveBtn
                className="d-center mt-2 animated zoomIn py-2 px-3 w-100"
                isSaving={isLoading}
                text="Connexion"
                type="btn-primary"
                typeBtn="submit"
                margin=""
                color={"white"}
              />
            )}
          </div>
          <div className="text-center mt-2">
            <button
              type="button"
              onClick={() => setForgotPassword(true)}
              className="btn btn-link  shadow-none border-0"
            >
              J'ai oublié mon mot de passe
            </button>
          </div>
        </form>
      ) : (
        <ForgotPassword setForgotPassword={setForgotPassword} />
      )}
    </Page>
  );
};

export default Login;
