import { DateTime } from "luxon";
import React from "react";
import useStore from "../../../context/useStore";
import Control from "../../common/Control";

const RecipientListFilters = ({
  search,
  updateSearch,
  resetFilters,
  orgs,
  searchRecipients,
}) => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;

  return (
    <form
      className="row"
      onSubmit={(e) => {
        e.preventDefault();
        searchRecipients();
      }}
    >
      <div className="col-12 col-md-4">
        <Control
          margin={"my-0"}
          label={<>Recherche</>}
          placeholder={"Nom, Prénom, email..."}
          name="terms"
          value={search.filters.terms}
          change={updateSearch}
        />
        <div style={{ lineHeight: "12px" }} className=" px-3">
          <i className="text-muted" style={{ fontSize: "0.7rem" }}>
            Si vous recherchez un NIR, entrez le NIR complet
          </i>
        </div>
      </div>
      <div className="col-12 col-md-4">
        <Control
          margin={"my-0"}
          label="Compte activé ?"
          type="select"
          name="isActivated"
          value={search.filters.isActivated}
          selectFirstLabel="Tous"
          datas={[
            { id: 1, name: "Activé" },
            { id: 0, name: "Non activé" },
          ]}
          change={updateSearch}
        />
      </div>
      <div className="col-12 col-md-4">
        <Control
          margin={"my-0"}
          label="Origine"
          type="select"
          name="origin"
          value={search.filters.origin}
          selectFirstLabel="Tous"
          datas={constants.RECIPIENT_ORIGINS}
          change={updateSearch}
        />
      </div>
      <div className="col-12 col-md-4">
        <Control
          margin={"my-0"}
          label="Possède un RDSP"
          type="select"
          name="rdspAvailable"
          value={search.filters.rdspAvailable}
          selectFirstLabel="Tous"
          datas={[
            { id: 1, name: "Oui" },
            { id: 0, name: "Non" },
          ]}
          change={updateSearch}
        />
      </div>
      <div className="col-12 col-md-4">
        <Control
          margin={"my-0"}
          label="Orientation"
          type="select"
          name="orientation"
          dataIndex="slugName"
          value={search.filters.orientation}
          selectFirstLabel="Tous"
          datas={[
            ...orgs,
            {
              id: "none",
              slugName: "none",
              name: "Sans orientation",
            },
          ]}
          change={updateSearch}
        />
      </div>
      <div className="col-12 col-md-4">
        <Control
          margin={"my-0"}
          label="Situation familiale"
          type="select"
          name="maritalStatus"
          dataIndex="id"
          value={search.filters.maritalStatus}
          selectFirstLabel="Tous"
          datas={constants.MARITAL_STATUS}
          change={updateSearch}
        />
      </div>
      <div className="col-12 col-md-4">
        <Control
          margin={"my-0"}
          label="Date minimum de dernière orientation"
          type="date"
          name="lastOrientationAt"
          value={DateTime.fromISO(search.filters.lastOrientationAt).toFormat(
            "yyyy-MM-dd"
          )}
          change={updateSearch}
        />
        <button
          className="btn btn-sm btn-default mr-1"
          type="button"
          onClick={() =>
            updateSearch({
              target: {
                name: "lastOrientationAt",
                value: "",
              },
            })
          }
        >
          Vider la date
        </button>
        <button
          className="btn btn-sm btn-default"
          type="button"
          onClick={() =>
            updateSearch({
              target: {
                name: "lastOrientationAt",
                value: DateTime.local().minus({ months: 3 }).toISO(),
              },
            })
          }
        >
          Mettre à -3 mois
        </button>
      </div>
      <div className="col-12 col-md-4 d-center">
        <Control
          label="Dont l'avant dernière orientation a été décidée en EG"
          type="checkbox"
          checked={search.filters.isLastOrientationAfterEG}
          name="isLastOrientationAfterEG"
          change={updateSearch}
        />
      </div>
      <div className="col-12 col-md-4 ">
        <Control
          label="Archivé le"
          type="date"
          value={DateTime.fromISO(search.filters.archivedAt).toFormat(
            "yyyy-MM-dd"
          )}
          name="archivedAt"
          change={updateSearch}
        />
        <button
          className="btn btn-sm btn-default mr-1"
          type="button"
          onClick={() =>
            updateSearch({
              target: {
                name: "archivedAt",
                value: "",
              },
            })
          }
        >
          Vider la date
        </button>
      </div>
      <div className="col-12 col-md-4 ">
        <Control
          label="Situation professionnelle"
          type="select"
          value={search.filters.professionalSituation}
          change={updateSearch}
          name="professionalSituation"
          datas={constants.PROFESSIONAL_SITUATIONS}
          dataIndex="id"
          dataLabel="name"
        />
      </div>
      <div className="col-12 col-md-4 ">
        <Control
          label="Création à partir du"
          type="date"
          value={DateTime.fromISO(search.filters.createdAtAfter).toFormat(
            "yyyy-MM-dd"
          )}
          name="createdAtAfter"
          change={updateSearch}
        />
        <button
          className="btn btn-sm btn-default mr-1"
          type="button"
          onClick={() =>
            updateSearch({
              target: {
                name: "createdAtAfter",
                value: "",
              },
            })
          }
        >
          Vider la date
        </button>
      </div>
      <div className="col-12 col-md-4 ">
        <Control
          label="Création égale ou avant le"
          type="date"
          value={DateTime.fromISO(search.filters.createdAtBefore).toFormat(
            "yyyy-MM-dd"
          )}
          name="createdAtBefore"
          change={updateSearch}
        />
        <button
          className="btn btn-sm btn-default mr-1"
          type="button"
          onClick={() =>
            updateSearch({
              target: {
                name: "createdAtBefore",
                value: "",
              },
            })
          }
        >
          Vider la date
        </button>
        <div className="mt-2">
          <button
            type="button"
            className="btn btn-light"
            onClick={resetFilters}
          >
            Réinitialiser les filtres par défaut
          </button>
        </div>
      </div>
    </form>
  );
};

export default RecipientListFilters;
